import Vue from 'vue'
import App from './App.vue'
//axios
import request from "@/api/request";
import coreRequest  from '@/api/coredslApi';
//vue-router
import router from './router'  //刚刚建立的文件夹
import VueRouter from 'vue-router'
//vuex
import store from './store'
//element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './registerServiceWorker'

//codemirror
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
Vue.use(VueCodemirror)

Vue.prototype.archApiJava = request
Vue.prototype.archapi = coreRequest
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter)
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
