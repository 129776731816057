import Vue from 'vue'
import Vuex from 'vuex'
// import readrdlfile from './modules/readrdlfile.js'

Vue.use(Vuex)

const LOCAL_STORAGE_KEY = 'myVuexStore'; 

 const store = new Vuex.Store({
    //数据，相当于data
 
    state: {
        deferredPrompt: null,
        projectID: null,  // 工程ID

        isDataSaveAddrmap: true, // 数据是保存或更新状态判断-addr中的Dialog弹窗
        isDataSaveRegfile: true, // 数据是保存或更新状态判断-regfile中的Dialog弹窗
        // isDataSaveInnRegfile: true, // 数据是保存或更新状态判断-内部regfile中的Dialog弹窗
        isDataSaveReg: true, // 数据是保存或更新状态判断-reg中的Dialog弹窗
        isDataSaveField: true, // 数据是保存或更新状态判断-field中的Dialog弹窗
        isDataSaveMem: true, // 数据是保存或更新状态判断-mem中的Dialog弹窗
        
        regDefinedData: null,  // 寄存器属性定义总表单数据存储

    },
    getters: {
        isDataSaveAddrmap: state => state.isDataSaveAddrmap
    },
    //里面定义方法，操作state方发
    mutations: {
        // 状态切换
        toggleDataSaveAddrmap(state){
            state.isDataSaveAddrmap = !state.isDataSaveAddrmap;
            // console.log("isDataSaveAddrmap状态开关---", state.isDataSaveAddrmap);
            saveToLocalStorage(state);
            
        },
        toggleDataSaveRegfile(state){
            state.isDataSaveRegfile = !state.isDataSaveRegfile;
            // console.log("isDataSaveRegfile状态开关---", state.isDataSaveRegfile);
            saveToLocalStorage(state);
        },
        toggleDataSaveReg(state){
            state.isDataSaveReg = !state.isDataSaveReg;
            // console.log("isDataSaveReg状态开关---", state.isDataSaveReg);
            saveToLocalStorage(state);
        },
        toggleDataSaveField(state){
            state.isDataSaveField = !state.isDataSaveField;
            // console.log("isDataSaveField状态开关---", state.isDataSaveField);
            saveToLocalStorage(state);
        },
        toggleDataSaveMem(state){
            state.isDataSaveMem = !state.isDataSaveMem;
            // console.log("isDataSaveMem状态开关---", state.isDataSaveMem);
            saveToLocalStorage(state);
        },

        saveRegDefinedData(state, newValue){
            state.regDefinedData = newValue;
            // console.log("00000", state.regDefinedData);
            saveToLocalStorage(state);
        },
        // 数据复位
        resetState(state){ 
            state.isDataSaveAddrmap = true;
            state.isDataSaveRegfile = true;
            state.isDataSaveReg = true;
            state.isDataSaveField = true;
            state.isDataSaveMem = true;
        },

    },
    // 操作异步操作mutation
    actions: {
        toggleDataSaveAddrmap({commit}) {
            commit('toggleDataSaveAddrmap');
        },
        toggleDataSaveRegfile({commit}) {
            commit('toggleDataSaveRegfile');
        },
        toggleDataSaveReg({commit}) {
            commit('toggleDataSaveReg');
        },
        toggleDataSaveField({commit}) {
            commit('toggleDataSaveField');
        },
        toggleDataSaveMem({commit}) {
            commit('toggleDataSaveMem');
        },
        resetState({commit}){
            commit('resetState');
        },

    },
    modules: {
        // readrdlfile
    },

})

// 从 LocalStorage 恢复状态  
const loadFromLocalStorage = () => {  
    const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);  
    if (savedState) {  
        return JSON.parse(savedState);  
    }  
    return {};  
};  

// 存储状态到 LocalStorage  
const saveToLocalStorage = (state) => {  
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));  
};  

// 初始化状态  
const initialState = loadFromLocalStorage();  
store.replaceState(Object.assign(store.state, initialState)); 

export default store;