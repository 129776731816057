import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/',
    redirect: '/ide-home',
  },
  
{
  path: '/ide-home',
  name: 'ideHome',
  meta:{
    title: 'Arch Studio'
  },

  component: () => import('@/views/ide/home/ideHome.vue'),
  children: [
    {
      path: 'visualization',
      name: 'visualization',
      meta:{
        title: '数据可视化'
      },
      component: () => import('@/views/ide/visualization/visualizationWindow.vue'),
    },
    {
      path: 'ASMvisualization',
      name: 'visualization',
      meta:{
        title: '汇编指令转汇编'
      },
      component: () => import('@/views/DAGtoASM/DAGtoASM.vue'),
    },
    {
      path: 'coreDSLtemlate',
      name: 'coreDSL',
      meta:{
        title: 'coreDSL自动生成'
      },
      component: () => import('@/views/coreDSL/coreDSL'),
    },
    {
      path: 'welcome',
      name: 'welcome',
      meta:{
        title: '欢迎你使用Arch Studio'
      },
      component: () => import('@/views/ide/welcome/welcome'),
    },
    {
      path: 'reg-defin-oder',
      name: 'regDefinOder',
      meta:{
        title: '操作添加'
      },
      component: () => import('@/views/regDefined/regDefinOder'),
    },
    {
      path: 'main-page',
      name: 'mainPage',
      meta:{
        title: '主页面'
      },
      component: () => import('@/views/ide/main/mainPage.vue'),
    },
  
  ]
}
]
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  routes: constantRouterMap
})


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  document.title = to.meta.title || 'Arch Studio' //修改网页的title

  next()

})

export default router
