<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// //获取权限
// if (Notification.permission === 'default') {
//   console.log("执行");
//   //请求权限
//   Notification.requestPermission()
// }

// import {getByIdlevel} from "@/uilts/level"
export default {
  name: 'App',
  mounted() {
    // getByIdlevel("1712724290274521090")
    // console.log( this.getByIdlevelFun())
      // getByIdlevel("1747819389891702786").then(res=>{
      //   console.log(res)
      // })
  },
  methods:{
    // async getByIdlevelFun(){
    //   return await getByIdlevel("1719916103469756417");
    // }
  }
}
</script>

<style>
/*引入动画库css*/
@import "App-Animation.css";
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

#app {
  overflow: hidden;
}
</style>
