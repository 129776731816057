import axios from 'axios'

//请求二次封装
// https://arch.hotchip.top/arch-api/
const coreRequest = axios.create({
  // baseURL: `http://39.108.114.30:13000/`, 
  baseURL: `https://arch.hotchip.top/arch-api/`,
  timeout: 80000,
})



// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
coreRequest.interceptors.request.use(config => {

  // console.log(config.headers['Content-Type']);
  if (config.headers['Content-Type'] !== "multipart/form-data") {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

  }
  let token = localStorage.getItem("token");

  if (token) {
    config.headers['token'] = token; // 设置请求头
  }


  return config
}, error => {
  return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
coreRequest.interceptors.response.use(
  response => {
    let res = response.data;
    // 如果是返回的文件
    if (response.config.responseType === 'blob') {
      return res
    }
    // 兼容服务端返回的字符串数据
    if (typeof res === 'string') {
      // res = res ? JSON.parse(res) : res
    }
    return res;
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)


export default coreRequest
